.rpv-core__inner-page {
  background-color: transparent !important;
}

.rpv-core__page-layer::after {
  box-shadow: none !important;
}

.rpv-page-navigation__current-page-input {
  text-align: center;
  width: 40px;
}

.rpv-core__textbox {
  height: 28px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.12);
  border: none;
  padding: 0px;
  text-align: center;
  color: white;
}
