@keyframes dotty {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingStyle::after {
  display: inline-block;
  animation: dotty 1s infinite;
  content: "...";
}

.iconLoading {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.ql-container {
  font-size: 13px !important;
}

.upDownAnimatedIcon {
  animation: upDown 1s infinite;
}

@keyframes upDown {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: -4px;
  }
  100% {
    margin-top: 0;
  }
}

#proxy-renderer,
#msdoc-renderer {
  height: 100%;
}

.documentBackground {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3.348px 6.697px 2.511px rgba(0, 0, 0, 0.12);
}

.canvasBackground {
  border-radius: 4px !important;
}

.canvasBackground canvas {
  border-radius: 4px !important;
}
